import React, { useState, useContext, useLayoutEffect } from "react";
import TCCLogo from "../../../svg/the-comparison-company-logo.svg";
import Status from "../../components/Status";
import { Menu } from "antd";
import { Link } from "gatsby";
import * as navbarUtils from "./navbarUtils";
import useNavigationData from "../../hooks/useNavigationData";
import WindowSizeContext from "../../contexts/windowSizeContext";
import useHasMounted from "../../hooks/useHasMounted";

import "./navbar.css";

const { SubMenu } = Menu;

export default () => {
  const navigationData = useNavigationData();
  const windowSize = useContext(WindowSizeContext);
  const hasMounted = useHasMounted();

  const [isExpanded, setIsExpansion] = useState(false);
  const [isVerticalMenu, setIsVerticalMenu] = useState(false);

  useLayoutEffect(() => {
    setIsVerticalMenu(!windowSize.isLargeScreenView);
  }, [windowSize, windowSize.isLargeScreenView, isExpanded]);

  if (!hasMounted) {
    return null;
  }

  return (
    <section className="container pt-8 mx-auto">
      <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
        {/* left column */}
        <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-2/12 xl:my-px xl:px-px xl:w-2/12">
          <div className="flex">
            <div className="flex w-6/12 pl-1 lg:pl-0 lg:w-full">
              <Link to="/" className="cursor-pointer" aria-label="The Comparison Company">
                <TCCLogo className="w-full h-8 pl-0 md:h-10" />
              </Link>
            </div>
            <div className="flex items-center justify-end w-6/12 pr-1 lg:pr-0 lg:hidden">
              <button
                onClick={() => {
                  setIsExpansion(!isExpanded);
                }}
                className="px-3 py-3 mr-0 text-right text-blue-900 border border-blue-900 rounded "
              >
                <svg className="w-3 h-3 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* content  */}
        <div className="w-full px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-full lg:my-px lg:px-px lg:w-8/12 xl:my-px xl:px-px xl:w-8/12">
          {/* Content */}
          <div className={`${isExpanded || windowSize.isLargeScreenView ? `block` : `hidden`} w-full flex justify-center items-center overflow-hidden`}>
            <Menu mode={isVerticalMenu ? `inline` : `horizontal`} className="inline-block h-full text-gray-800 align-top border-none border-b-blue-900">
              {navigationData.parentMenuData.map((parentMenuLink) => (
                <SubMenu
                  icon={navbarUtils.getMenuIconFor(parentMenuLink)}
                  title={navbarUtils.getCasedSpacedMenuTextFrom(parentMenuLink)}
                  className="text-xs md:text-sm"
                  key={navbarUtils.createMenuKeyFrom(parentMenuLink)}
                  style={{ marginRight: `${windowSize.windowSize === "lg" ? "0px" : "2px"}` }}
                >
                  {navbarUtils.getParentSubMenusFor(parentMenuLink, navigationData.menuData).map((subMenuLink) => (
                    <Menu.Item key={navbarUtils.createMenuKeyFrom(subMenuLink.menuText)}>
                      <Link to={navbarUtils.generateSubCategorySlugFor(subMenuLink)}>{subMenuLink.menuText}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              ))}
              <Menu.Item key="blog-index">
                <Link to="/blog" className="">
                  <span className="text-gray-800">Blog</span>
                </Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className={`${isExpanded ? `block` : `hidden `} w-full pl-0 h-full overflow-hidden`}>
            <Status />
          </div>
        </div>
        {/* right column  */}
        <div className="hidden w-full px-px my-px overflow-hidden lg:block sm:my-px sm:px-px sm:w-full md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-2/12 xl:my-px xl:px-px xl:w-2/12">
          <Status />
        </div>
      </div>
    </section>
  );
};
