import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import StudentLoanIllustration from "../../../../svg/comparison-illustrations/studentrefinancingloan.svg";
import SplashSVG from "../../../../svg/ComparisonResultGridLogos/studentloans/SplashFinancial.svg";
import SoFiSVG from "../../../../svg/ComparisonResultGridLogos/studentloans/sofi.svg";
import CitizensSVG from "../../../../svg/ComparisonResultGridLogos/studentloans/citizens.svg";
import EarnestSVG from "../../../../svg/ComparisonResultGridLogos/studentloans/Earnest.svg";
import MefaSVG from "../../../../svg/ComparisonResultGridLogos/studentloans/mefa.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  StudentLoanIllustration,
  SplashSVG,
  SoFiSVG,
  CitizensSVG,
  EarnestSVG,
  MefaSVG,
  React
};