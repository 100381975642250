import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ElectricRatesIllustration from "../../../../svg/ComparisonResultPageIllustrations/electricityratesfortexas.svg";
import LoneStarSVG from "../../../../svg/ComparisonResultGridLogos/electricrates/lonestar.svg";
import NewPowerSVG from "../../../../svg/ComparisonResultGridLogos/electricrates/newpower.svg";
import PowerNextSVG from "../../../../svg/ComparisonResultGridLogos/electricrates/powernext.svg";
import PulsePowerSVG from "../../../../svg/ComparisonResultGridLogos/electricrates/pulsepower.svg";
import XoomSVG from "../../../../svg/ComparisonResultGridLogos/electricrates/xoom.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ElectricRatesIllustration,
  LoneStarSVG,
  NewPowerSVG,
  PowerNextSVG,
  PulsePowerSVG,
  XoomSVG,
  React
};