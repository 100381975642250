import React, {useContext} from "react";
import { Link } from "gatsby";
import WindowSizeContext from "../../contexts/windowSizeContext";

export default () => {
  const windowSize = useContext(WindowSizeContext);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <section>
      <div className={`container ${windowSize.isLargeScreenView ? `py-6 ` : `py-4 `}`}>
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden xl:w-1/12"></div>

          <div className="w-full overflow-hidden text-center">
            <div className="py-4 mx-auto align-middle bg-blue-500 rounded-xs lg:pt-4 lg:h-16">
              <p className="inline-block mr-2 text-white">It's easy to get started &amp; we ensure all of your info is kept confidential</p>
              <Link
                to="/app/login"
                className="inline-block px-3 py-2 text-gray-500 bg-yellow-500 border border-yellow-500 rounded focus:text-gray-500 focus:bg-yellow-500 focus:border-yellow-500 hover:bg-gray-500 hover:border-gray-500 hover:text-yellow-500"
              >
                Sign Up
              </Link>
            </div>
          </div>

          <div className="w-full overflow-hidden xl:w-3/12"></div>
        </div>
      </div>
    </section>
  );
};
