import React, { useContext } from "react";
import { Popover } from "antd";
import WindowSizeContext from "../../contexts/windowSizeContext";

/**
 * Returns an element that renders the Advertising Disclosure Popover element.
 */
export default () => {
  const windowSize = useContext(WindowSizeContext);

  const toolTipText = (
    <div>
      <h1 className="text-base font-bold lg:text-lg">Advertiser Disclosure</h1>
      <p className="text-sm">
        We want everyone to be able to find the best deal for any utility, business or residential. We have used, reviewed and researched every Provider on our site, something that becomes
        increasingly hard as we add hundreds of prices to the site. We eventually want every provider on the market reviewed and rated by us. Bear with us as this takes time.
      </p>
      <p className="text-sm">
        We can provide this service to you because we’re passionate, but also because we may receive some compensation from the Partners. The compensation may influence which plans we choose to
        compare and write about, but it does NOT effect our recommendations or advice, which are based on thousands of hours of anonymous research. Our partners cannot pay us to guarantee favorable
        reviews of their products or services.
      </p>
    </div>
  );

  return (
    <div className="px-2 pb-4">
      <Popover className="text-blue-500" autoAdjustOverflow={true} trigger={windowSize.isLargeScreenView ? "hover" : "click"} title={toolTipText}>
        <span className="text-sm border border-t-0 border-l-0 border-r-0 border-blue-500">Advertising Disclosure</span>
      </Popover>
    </div>
  );
};
