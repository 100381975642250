import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import HomeInsuranceIllustration from "../../../../svg/ComparisonResultPageIllustrations/homeinsurance.svg";
import AllStateSVG from "../../../../svg/ComparisonResultGridLogos/homeinsurance/allstate.svg";
import HippoSVG from "../../../../svg/ComparisonResultGridLogos/homeinsurance/hippo.svg";
import SmartFinancialSVG from "../../../../svg/ComparisonResultGridLogos/homeinsurance/smartfinancial.svg";
import UPCSVG from "../../../../svg/ComparisonResultGridLogos/homeinsurance/upcinsurance.svg";
import ARWSVG from "../../../../svg/ComparisonResultGridLogos/homeinsurance/americanresidentialwarranty.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  HomeInsuranceIllustration,
  AllStateSVG,
  HippoSVG,
  SmartFinancialSVG,
  UPCSVG,
  ARWSVG,
  React
};