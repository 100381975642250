import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import CellHandsetIllustration from "../../../../svg/comparison-illustrations/cellphones.svg";
import TingLogo from "../../../../svg/ComparisonResultGridLogos/cellphones/ting.svg";
import XfinityLogo from "../../../../svg/ComparisonResultGridLogos/cellphones/xfinitymobile.svg";
import MintMobileLogo from "../../../../svg/ComparisonResultGridLogos/cellphones/mintmobile.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultColumnContentBackgroundContainer,
  CellHandsetIllustration,
  TingLogo,
  XfinityLogo,
  MintMobileLogo,
  React
};