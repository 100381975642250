import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import PayrollIllustration from "../../../../svg/ComparisonResultPageIllustrations/businesspayroll.svg";
import WagePointSVG from "../../../../svg/ComparisonResultGridLogos/businesspayroll/wagepoint.svg";
import GustoSVG from "../../../../svg/ComparisonResultGridLogos/businesspayroll/gusto.svg";
import SquareSVG from "../../../../svg/ComparisonResultGridLogos/businesspayroll/square.svg";
import OnPaySVG from "../../../../svg/ComparisonResultGridLogos/businesspayroll/onpay.svg";
import SurePayrollSVG from "../../../../svg/ComparisonResultGridLogos/businesspayroll/surepayroll.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  PayrollIllustration,
  WagePointSVG,
  GustoSVG,
  SquareSVG,
  OnPaySVG,
  SurePayrollSVG,
  React
};