import React from "react";

import BusinessBankingSvg from "../../svg/RecommendedProductIcons/Business/businessbanking.svg";
import BusinessCreditCardsSvg from "../../svg/RecommendedProductIcons/Business/businesscreditcards.svg";
import BusinessCreditServicesSvg from "../../svg/RecommendedProductIcons/Business/businesscreditservices.svg";
import BusinessInsuranceSvg from "../../svg/RecommendedProductIcons/Business/businessinsurance.svg";
import BusinessLoansSvg from "../../svg/RecommendedProductIcons/Business/businessloans.svg";
import BusinessPasswordManagementSvg from "../../svg/RecommendedProductIcons/Business/businesspassword.svg";
import BusinessPayrollSvg from "../../svg/RecommendedProductIcons/Business/businesspayroll.svg";
import EmailMarketingServicesSvg from "../../svg/RecommendedProductIcons/Business/emailmarketiing.svg";
import SchedulingServicesSvg from "../../svg/RecommendedProductIcons/Business/schedulingservices.svg";
import VoipServicesSvg from "../../svg/RecommendedProductIcons/Business/voipservices.svg";
import WebDomainManagementSvg from "../../svg/RecommendedProductIcons/Business/webdomainservies.svg";
import WebHostingSvg from "../../svg/RecommendedProductIcons/Business/webdomainservies.svg";
import CellPhonesSvg from "../../svg/RecommendedProductIcons/Cell/cellphones.svg";
import CellPlansSvg from "../../svg/RecommendedProductIcons/Cell/cellplans.svg";
import CreditMonitoringServicesSvg from "../../svg/RecommendedProductIcons/Financial/creditmonitoring.svg";
import CryptoServicesSVG from "../../svg/RecommendedProductIcons/Financial/cryptocurrency.svg";
import IdProtectionServicesSvg from "../../svg/RecommendedProductIcons/Financial/identityprotection.svg";
import PersonalBankingSvg from "../../svg/RecommendedProductIcons/Financial/personalbanking.svg";
import PersonalBudgetingSvg from "../../svg/RecommendedProductIcons/Financial/personalbudgeting.svg";
import PersonalCreditCardsServicesSvg from "../../svg/RecommendedProductIcons/Financial/personalcreditcards.svg";
import PersonalDebtServicesSvg from "../../svg/RecommendedProductIcons/Financial/personaldebtservices.svg";
import PersonalTaxServicesSvg from "../../svg/RecommendedProductIcons/Financial/personaltaxservices.svg";
import HomeCleaningServicesSvg from "../../svg/RecommendedProductIcons/Home/homecleaning.svg";
import InternetSvg from "../../svg/RecommendedProductIcons/Home/homeinternet.svg";
import TVPackagesSvg from "../../svg/RecommendedProductIcons/Home/tvicon.svg";
import PasswordManagersSvg from "../../svg/RecommendedProductIcons/Home/passwordmanager.svg";
import CarInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/carinsurance.svg";
import HealthInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/healthinsurance.svg";
import HomeInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/homeinsurance.svg";
import LifeInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/lifeinsurance.svg";
import MedicareInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/medicareinsurance.svg";
import MotorCycleInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/motorcycleinsurance.svg";
import RentersInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/rentersinsurance.svg";
import CarLoansSvg from "../../svg/RecommendedProductIcons/Loans/carloans.svg";
import HomeLoansSvg from "../../svg/RecommendedProductIcons/Loans/homeloans.svg";
import PersonalLoansSvg from "../../svg/RecommendedProductIcons/Loans/personalloans.svg";
import RefinancingLoansSvg from "../../svg/RecommendedProductIcons/Loans/refinancingloans.svg";
import StudentRefinancingSvg from "../../svg/RecommendedProductIcons/Loans/studentrefinancing.svg";
import ElectricRatesSvg from "../../svg/RecommendedProductIcons/Utilities/electricityrates.svg";
import GasRatesSvg from "../../svg/RecommendedProductIcons/Utilities/electricityrates.svg";

/**
 * The configurations defined here require the following fields:
 *
 * - productCategory - The product category name as shown in the corresponding navigation menu item. This will also be used as the recommended product tiles heading if no title field is defined.
 * - title - (Optional) - The text used as the header in the recommended product tile.
 * - text - The body text shown in the recommended product tile.
 * - image - The SVG element to use (with styling applied) to the recommended product tile.
 * - relatedProducts - A string array containing the product category's that will consume the configuration.
 */
export default {
  recommendedProductConfigurations: [
    {
      productCategory: "Business Banking",
      text: "Find a bank with the right services to support your business.",
      image: <BusinessBankingSvg className="h-6" />,
      relatedProducts: [
        "business loans",
        "business credit cards",
        "VoIP Services",
        "business credit services",
        "business insurance",
        "Business Payroll Services & Software",
        "Business Password Management",
      ],
    },
    {
      productCategory: "Business Credit Cards",
      text: "Cards from $5 per employee & rates from 11.70%.",
      image: <BusinessCreditCardsSvg className="h-6" />,
      relatedProducts: ["business banking", "business loans", "web hosting services", "Business Credit Services", "VoIP Services", "business insurance", "Business Payroll Services & Software"],
    },
    {
      productCategory: "Business Credit Services",
      text: "Constant monitoring from $149 & Credit Reports from $39.95.",
      image: <BusinessCreditServicesSvg className="h-6" />,
      relatedProducts: ["business banking", "business loans", "web hosting services", "business credit cards", "VoIP Services", "business insurance", "Business Payroll Services & Software"],
    },
    {
      productCategory: "Business Insurance",
      text: "When It Comes to Business Insurance for Small Businesses, We’ve Got Your Back.",
      image: <BusinessInsuranceSvg className="h-6" />,
      relatedProducts: ["business loans", "business credit cards", "VoIP Services", "business credit services", "Business Payroll Services & Software", "Business Password Management"],
    },
    {
      productCategory: "Business Loans",
      text: "Get a loan to support your business with a rate from 6% APR.",
      image: <BusinessLoansSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "VoIP Services", "business credit services", "Business Payroll Services & Software", "Business Password Management"],
    },
    {
      productCategory: "Business Password Management",
      text: "Protect your business accounts by securely managing your passwords.",
      image: <BusinessPasswordManagementSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "VoIP Services", "business credit services", "Business Payroll Services & Software", "Business loans"],
    },
    {
      productCategory: "Business Payroll Services & Software",
      text: "Automate your payroll from $20 per month.",
      image: <BusinessPayrollSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "VoIP Services", "Email Marketing Services", "Business Password Management", "Business loans"],
    },
    {
      productCategory: "Email Marketing Services",
      text: "Discover the best email marketing services on the market today for your business.",
      image: <EmailMarketingServicesSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "VoIP Services", "Business Payroll Services & Software", "Business Password Management", "Business loans"],
    },
    {
      productCategory: "Scheduling Services",
      text: "Never Miss a Prospective Customer again with our list of Scheduling Services.",
      image: <SchedulingServicesSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "VoIP Services", "Business Payroll Services & Software", "Business Password Management", "Business loans"],
    },
    {
      productCategory: "VoIP Services",
      text: "Compare and Choose the Right VoIP Providers starting at $11.99 per month.",
      image: <VoipServicesSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "business banking", "Business Payroll Services & Software", "Business Password Management", "Business loans"],
    },
    {
      productCategory: "Web Domain Management",
      text: "The best web domain management services starting at $4.44 per month.",
      image: <WebDomainManagementSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "business banking", "Business Payroll Services & Software", "Business Password Management", "Business loans"],
    },
    {
      productCategory: "Web Hosting Services",
      text: "Finding a good web hosting service can take a lot of time and money.",
      image: <WebHostingSvg className="h-6" />,
      relatedProducts: ["business insurance", "business credit cards", "business banking", "Business Payroll Services & Software", "Business Password Management", "Business loans"],
    },
    {
      productCategory: "Cell Phones",
      text: "We test and rate hundreds of mobile phones each year.",
      image: <CellPhonesSvg className="h-6" />,
      relatedProducts: ["cell plans", "home loans", "car loans", "home tv packages", "home internet services", "life insurance", "student loan refinancing"],
    },
    {
      productCategory: "Cell Plans",
      text: "Best Low-Cost Cell-Phone Plans.",
      image: <CellPlansSvg className="h-6" />,
      relatedProducts: ["cell phones", "mortgage refinancing loans", "personal loans", "home tv packages", "electricity rates", "car insurance", "student loan refinancing"],
    },
    {
      productCategory: "Credit Monitoring Services",
      text: "Keep track of your credit and receive alerts on potential fraud. ",
      image: <CreditMonitoringServicesSvg className="h-6" />,
      relatedProducts: ["Identity protection Services", "Personal Budgeting Tools", "personal loans", "electricity rates", "home tv packages", "student loan refinancing", "personal credit cards"],
    },
    {
      productCategory: "Cryptocurrency Services",
      text: "Check out the best cryptocurrency savings accounts here.",
      image: <CryptoServicesSVG className="h-6" />,
      relatedProducts: [
        "Identity protection Services",
        "Personal Budgeting Tools",
        "personal loans",
        "personal bank accounts",
        "personal debt services",
        "student loan refinancing ",
        "personal credit cards",
      ],
    },
    {
      productCategory: "Identity Protection Services",
      text: "There's a victim every 3 seconds, so don't wait to get identity theft protection.",
      image: <IdProtectionServicesSvg className="h-6" />,
      relatedProducts: [
        "Cryptocurrency Services",
        "Personal Budgeting Tools",
        "mortgage Refinancing loans",
        "personal bank accounts",
        "personal debt services",
        "student loan refinancing",
        "Life Insurance",
      ],
    },
    {
      productCategory: "Personal Bank Accounts",
      text: "Find a new bank account with rates from 1.00% APY.",
      image: <PersonalBankingSvg className="h-6" />,
      relatedProducts: ["Personal Budgeting Tools", "home loans", "personal credit cards", "personal debt services", "student loan refinancing", "Life Insurance", "cell plans"],
    },
    {
      productCategory: "Personal Budgeting Tools",
      text: "The right budgeting tool can make managing money more effective, efficient, and enjoyable.",
      image: <PersonalBudgetingSvg className="h-6" />,
      relatedProducts: [
        "credit monitoring services",
        "home tv packages",
        "home insurance",
        "life insurance",
        "personal loans",
        "student loan refinancing",
        "mortgage refinancing loans",
        "personal credit cards",
      ],
    },
    {
      productCategory: "Personal Credit Cards",
      text: "Compare Credit card offers available to people of all credit levels.",
      image: <PersonalCreditCardsServicesSvg className="h-6" />,
      relatedProducts: ["Personal Budgeting Tools", "home tv packages", "car loans", "personal debt services", "student loan refinancing", "Life Insurance", "cell plans"],
    },
    {
      productCategory: "Personal Debt Services",
      text: "Best Credit & Debt Consolidation Programs for 2020: $2k-$100k.",
      image: <PersonalDebtServicesSvg className="h-6" />,
      relatedProducts: ["Personal credit cards", "home internet services", "home loans", "personal tax services", "electricity rates", "car Insurance", "cell phones"],
    },
    {
      productCategory: "Personal Tax Services",
      text: "Compare and explore your tax-saving opportunities and develop your tax strategy.",
      image: <PersonalTaxServicesSvg className="h-6" />,
      relatedProducts: ["Personal debt services", "personal bank accounts", "personal loans", "home tv packages", "electricity rates", "car Insurance", "cell plans"],
    },
    {
      productCategory: "Home Cleaning Services",
      text: "Cleaning You Can See & Feel.",
      image: <HomeCleaningServicesSvg className="h-6" />,
      relatedProducts: ["Home Loans", "personal credit cards", "cell phones", "home tv packages", "home internet services", "mortgage refinancing loans", "cell plans"],
    },
    {
      productCategory: "Home Internet Services",
      text: "Just enter your ZIP code to find internet providers near you.",
      image: <InternetSvg className="h-6" />,
      relatedProducts: [
        "cell phones",
        "identity protection services",
        "personal bank accounts",
        "personal budgeting tools",
        "personal debt services",
        "personal tax services",
        "home tv packages",
        "student loan refinancing",
        "home loans",
        "mortgage refinancing loans",
        "cell plans",
      ],
    },
    {
      productCategory: "Home TV Packages",
      text: "Find the right content to binge watch for $39.99.",
      image: <TVPackagesSvg className="h-6" />,
      relatedProducts: [
        "cell phones",
        "cell plans",
        "home internet services",
        "home security products",
        "home insurance",
        "life insurance",
        "personal loans",
        "student loan refinancing ",
        "mortgage refinancing loans",
      ],
    },
    {
      productCategory: "Password Managers",
      text: "The Best Password Managers to Secure Your Digital Life.",
      image: <PasswordManagersSvg className="h-6" />,
      relatedProducts: ["Home internet services", "personal credit cards", "cell phones", "home tv packages", "Student loan refinancing", "car insurance", "cell plans"],
    },
    {
      productCategory: "Car Insurance",
      text: "We're making car insurance surprisingly painless.",
      image: <CarInsuranceSvg className="h-6" />,
      relatedProducts: ["Home Insurance", "mortgage refinancing loans", "electricity rates", "home tv packages", "Student loan refinancing", "car insurance", "cell phones"],
    },
    {
      productCategory: "Health Insurance",
      text: "Compare Health Insurance Plans for Individuals and Family.",
      image: <HealthInsuranceSvg className="h-6" />,
      relatedProducts: ["Home Insurance", "student loan refinancing", "electricity rates", "home internet services", "life insurance", "personal bank accounts", "cell phones"],
    },
    {
      productCategory: "Home Insurance",
      text: "Get a homeowners quote online to start protecting your home, belongings and peace of mind.",
      image: <HomeInsuranceSvg className="h-6" />,
      relatedProducts: ["Life Insurance", "mortgage refinancing loans", "electricity rates", "home internet services", "renters insurance", "personal bank accounts", "home tv packages"],
    },
    {
      productCategory: "Life Insurance",
      text: "Life Insurance for All that Matters to You.",
      image: <LifeInsuranceSvg className="h-6" />,
      relatedProducts: ["Car Insurance", "cell phones", "electricity rates", "home internet services", "Home insurance", "personal bank accounts", "home tv packages"],
    },
    {
      productCategory: "Medicare Insurance",
      text: "Changing from the Marketplace to Medicare.",
      image: <MedicareInsuranceSvg className="h-6" />,
      relatedProducts: ["life Insurance", "cell plans", "electricity rates", "home tv packages", "Home insurance", "personal debt services", "car insurance"],
    },
    {
      productCategory: "Motorcycle Insurance",
      text: "How to Get Cheap Motorcycle Insurance",
      image: <MotorCycleInsuranceSvg className="h-6" />,
      relatedProducts: ["life Insurance", "Medicare insurance", "cell phones", "home tv packages", "Home loans", "personal debt services", "mortgage refinancing loans"],
    },
    {
      productCategory: "Renters Insurance",
      text: "Renters insurance that covers your belongings and more.",
      image: <RentersInsuranceSvg className="h-6" />,
      relatedProducts: ["Home Insurance", "life insurance", "cell plans", "home tv packages", "Home loans", "home tv packages", "home internet services"],
    },
    {
      productCategory: "Car Loans",
      text: "When you’re searching for an auto loan, it pays to shop around.",
      image: <CarLoansSvg className="h-6" />,
      relatedProducts: ["Car Insurance", "life insurance", "cell phones", "electricity rates", "Home loans", "home tv packages", "home internet services"],
    },
    {
      productCategory: "Home Loans",
      text: "Get the right home loan for you from 3% downpayments.",
      image: <HomeLoansSvg className="h-6" />,
      relatedProducts: [
        "cell phones",
        "home internet services",
        "home security products",
        "home tv packages",
        "home insurance",
        "life insurance",
        "personal loans",
        "student loan refinancing",
        "mortgage refinancing loans",
        "personal credit cards",
        "car loans",
      ],
    },
    {
      productCategory: "Personal Loans",
      text: "Shop around and compare personal loan rates from multiple lenders.",
      image: <PersonalLoansSvg className="h-6" />,
      relatedProducts: ["Home loans", "home insurance", "cell plans", "electricity rates", "Home loans", "home tv packages", "home internet services"],
    },
    {
      productCategory: "Mortgage Refinancing Loans",
      text: "Find a provider with ARM Terms from 3 Years.",
      image: <RefinancingLoansSvg className="h-6" />,
      relatedProducts: ["Student Loans Refinancing", "renters insurance", "cell phones", "electricity rates", "personal loans", "home tv packages", "home internet services"],
    },
    {
      productCategory: "Student Loan Refinancing",
      text: "Refinance your student loans and save up to $3000.",
      image: <StudentRefinancingSvg className="h-6" />,
      relatedProducts: [
        "cell phones",
        "credit monitoring services",
        "identity protection services",
        "personal bank accounts",
        "personal budgeting tools",
        "personal debt services",
        "personal tax services",
        "home internet services",
        "home security products",
        "home tv packages",
        "home insurance",
        "life insurance",
        "personal loans",
        "personal credit cards",
        "car loans",
        "cell plans",
      ],
    },
    {
      productCategory: "Electricity Rates",
      text: "Change your supplier & pay from just $57.45.",
      image: <ElectricRatesSvg className="h-6" />,
      relatedProducts: [
        "cell phones",
        "credit monitoring services",
        "identity protection services",
        "personal bank accounts",
        "personal budgeting tools",
        "personal debt services",
        "personal tax services",
        "home internet services",
        "home security products",
        "home tv packages",
        "home insurance",
        "life insurance",
        "student loan refinancing",
        "web hosting services",
        "home loans",
        "mortgage refinancing loans",
        "car loans",
        "cell plans",
      ],
    },
    {
      productCategory: "Gas Rates",
      text: "Select a rate plan and estimate your total bill for any given month.",
      image: <GasRatesSvg className="h-6" />,
      relatedProducts: [
        "cell phones",
        "credit monitoring services",
        "identity protection services",
        "personal bank accounts",
        "personal budgeting tools",
        "personal debt services",
        "personal tax services",
        "home internet services",
        "home security products",
        "home tv packages",
        "home insurance",
        "life insurance",
        "student loans refinancing",
        "web hosting services",
        "home loans",
        "mortgage refinancing loans",
        "car loans",
        "cell plans",
      ],
    },
  ],
};
