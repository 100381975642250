import React from "react";
import ComparisonResultGridRowLabelColumn from "../ComparisonResultGridRowLabelColumn";
import ComparisonResultColumn from "../ComparisonResultColumn";
import ComparisonResultGridExpander from "../ComparisonResultGridExpander";
import ComparisonResultGridLinkButtons from "../ComparisonResultGridLinkButtons";
import ComparisonResultMobileColumnHeaderCell from "../ComparisonResultMobileColumnHeaderCell";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { labelColumnConfiguration, dataColumnsConfiguration, gridImageMap, gridMetaData, renderExpander } = props;

  return (
    <section className="px-2">
      {dataColumnsConfiguration.map((column) => {
        return (
          <div key={uuidv4()} className="flex justify-center my-4 overflow-hidden bg-blue-100 font-body">
            <div className="container flex flex-wrap px-2 py-2">
              <ComparisonResultMobileColumnHeaderCell key={uuidv4()} imageMap={gridImageMap} columnConfiguration={column} />
              <ComparisonResultGridRowLabelColumn key={uuidv4()} columnConfiguration={labelColumnConfiguration} renderMode="visible" />
              <div className="flex flex-wrap w-1/2 bg-white rounded lg:w-3/4">
                <ComparisonResultColumn key={uuidv4()} columnConfiguration={column} imageMap={gridImageMap} renderMode="visible" parentGridMetaData={gridMetaData} />
              </div>
              <ComparisonResultGridExpander
                key={uuidv4()}
                labelColumnConfiguration={labelColumnConfiguration}
                dataColumnsConfiguration={[column]}
                gridImageMap={gridImageMap}
                renderExpander={renderExpander}
              />
              <ComparisonResultGridLinkButtons key={uuidv4()} renderMode="cell" dataColumnsConfiguration={[column]} />
            </div>
          </div>
        );
      })}
    </section>
  );
};
