import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import BusinessCreditServicesIllustration from "../../../../svg/ComparisonResultPageIllustrations/businesscreditservice.svg";
import DunBradstreetSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditservices/dun_bradstreet25px.svg";
import ExperianSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditservices/experian.svg";
import EquifaxSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditservices/equifax.svg";
import CreditSafeSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditservices/creditsafe.svg";
import TransunionSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditservices/transunion.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  BusinessCreditServicesIllustration,
  DunBradstreetSVG,
  ExperianSVG,
  EquifaxSVG,
  CreditSafeSVG,
  TransunionSVG,
  React
};