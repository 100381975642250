import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import DebtIllustration from "../../../../svg/ComparisonResultPageIllustrations/personaldebtservices.svg";
import MintSVG from "../../../../svg/ComparisonResultGridLogos/personaldebtservices/mint.svg";
import TallySVG from "../../../../svg/ComparisonResultGridLogos/personaldebtservices/tally.svg";
import DebtPayoffSlayerSVG from "../../../../svg/ComparisonResultGridLogos/personaldebtservices/debtpayoffassistant.svg";
import DebtFreeSVG from "../../../../svg/ComparisonResultGridLogos/personaldebtservices/debtfree.svg";
import QuickenSVG from "../../../../svg/ComparisonResultGridLogos/personaldebtservices/quicken.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  DebtIllustration,
  MintSVG,
  TallySVG,
  DebtPayoffSlayerSVG,
  DebtFreeSVG,
  QuickenSVG,
  React
};