import React from "react";
import UserLocation from "../UserLocation";
import PropTypes from "prop-types";

/**
 * Renders a component that consumes an instance of the UserLocation component within a text UI element.
 *
 * @param {string} productCategory A string containing the name of the associated Product Category deals being shown.
 */
const ComparisonResultHeroUserLocation = (props) => {
  return (
    <div className="flex flex-wrap pt-2 pb-2 overflow-hidden lg:pt-0 lg:pb-4">
      <div className="w-full pl-2 overflow-hidden text-center text-blue-500 lg:text-left lg:pl-1 lg:pt-6">
        <span className="inline-block mr-2 ">Our top 5 {props.productCategory} for </span>
        <span className="inline-block">
          <UserLocation />
        </span>
      </div>
    </div>
  );
};

ComparisonResultHeroUserLocation.propTypes = {
  productCategory: PropTypes.string.isRequired,
};

ComparisonResultHeroUserLocation.defaultProps = {
  productCategory: "deals",
};
export default ComparisonResultHeroUserLocation;
