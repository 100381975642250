import React from "react";
import { Button } from "antd";

export default () => {
  return (
    <div className="flex flex-wrap items-center content-center justify-center overflow-hidden ">
      <div className="inline-block w-6/12 overflow-hidden lg:my-px lg:px-px lg:w-1/2 xl:my-px xl:px-px xl:w-1/2">
        <Button
          href="/app/login"
          size="large"
          type="link"
          className="w-full text-base text-white cursor-pointer bg-yellow-landingPage800 border-yellow-landingPage800 lg:text-gray-landingPage800 lg:text-right lg:ml-0 lg:w-full lg:my-1 lg:border-none lg:bg-white hover:text-gray-800"
        >
          Sign In
        </Button>
      </div>

      <div className="inline-block w-6/12 overflow-hidden lg:my-px lg:px-px lg:w-1/2 xl:my-px xl:px-px xl:w-1/2">
        <Button
          href="/app/login"
          size="large"
          type="primary"
          className="w-full mt-0 mb-0 font-bold text-white border lg:shadow-md bg-green-landingPage600 border-green-landingPage600 sm:mt-0 sm:mb-0 lg:mb-2 lg:w-full text-large lg:bg-green-landingPage700 lg:border-green-landingPage700 lg:rounded lg:hover:border-green-landingPage900 lg:hover:bg-green-landingPage900 lg:hover:text-white"
        >
          Join Us
        </Button>
      </div>
    </div>
  );
};
