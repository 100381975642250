import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import PersonalLoansIllustration from "../../../../svg/ComparisonResultPageIllustrations/personalloan.svg";
import AvantSVG from "../../../../svg/ComparisonResultGridLogos/personalloans/avant.svg";
import BestEggSVG from "../../../../svg/ComparisonResultGridLogos/personalloans/bestegg.svg";
import UpstartSVG from "../../../../svg/ComparisonResultGridLogos/personalloans/upstart.svg";
import PropsperSVG from "../../../../svg/ComparisonResultGridLogos/personalloans/prosper.svg";
import PayoffSVG from "../../../../svg/ComparisonResultGridLogos/personalloans/payoff.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  PersonalLoansIllustration,
  AvantSVG,
  BestEggSVG,
  UpstartSVG,
  PropsperSVG,
  PayoffSVG,
  React
};