import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import PasswordManagerIllustration from "../../../../svg/ComparisonResultPageIllustrations/passwordmanagers.svg";
import DashLaneSVG from "../../../../svg/ComparisonResultGridLogos/passwordmanagers/dashlane.svg";
import KeeperSVG from "../../../../svg/ComparisonResultGridLogos/passwordmanagers/keeper.svg";
import LastpassSVG from "../../../../svg/ComparisonResultGridLogos/passwordmanagers/lastpass.svg";
import BitWardenSVG from "../../../../svg/ComparisonResultGridLogos/passwordmanagers/bitwarden.svg";
import OnePasswordSVG from "../../../../svg/ComparisonResultGridLogos/passwordmanagers/onepassword.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  PasswordManagerIllustration,
  DashLaneSVG,
  KeeperSVG,
  LastpassSVG,
  BitWardenSVG,
  OnePasswordSVG,
  React
};