import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import SchedulingIllustration from "../../../../svg/ComparisonResultPageIllustrations/schedulingservices .svg";
import AcuitySchedulingSVG from "../../../../svg/ComparisonResultGridLogos/schedulingservices/acuityscheduling.svg";
import JobberSVG from "../../../../svg/ComparisonResultGridLogos/schedulingservices/jobber.svg";
import ScheduFlowSVG from "../../../../svg/ComparisonResultGridLogos/schedulingservices/scheduflow.svg";
import SimplyBookSVG from "../../../../svg/ComparisonResultGridLogos/schedulingservices/simplybookme.svg";
import TenToEightSVG from "../../../../svg/ComparisonResultGridLogos/schedulingservices/tentoeight.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  SchedulingIllustration,
  AcuitySchedulingSVG,
  JobberSVG,
  ScheduFlowSVG,
  SimplyBookSVG,
  TenToEightSVG,
  React
};