/**
 * Determines if the provided string is undefined, null or empty.
 *
 * @param {string} string The string to test.
 * @returns {boolean} True if the string is undefined, null or empty otherwise false;
 */
export const isNullEmptyOrUndefined = (string) => {
  return string === undefined || string === null || string === "";
};

/**
 * Determines if the provided string is not undefined, null or empty.
 *
 * @param {string} string The string to test.
 * @returns {boolean} False if the string is undefined, null or empty otherwise true;
 */
export const isNotNullEmptyOrUndefined = (string) => {
  return !isNullEmptyOrUndefined(string);
};

/**
 * Converts the provided string to Sentence Case.
 *
 * @param {string} string The string to convert to Sentence Case.
 * @returns {string} The converted string.
 */
export const convertToSentenceCase = (string) => {
  if (isNullEmptyOrUndefined(string)) return string;

  const SPACE = " ";

  const sentenceElements = string
    .toLowerCase()
    .split(SPACE)
    .map((element) => element[0].toUpperCase() + element.slice(1));

  return sentenceElements.join(SPACE);
};

export const convertToLowerCase = (string) => {
  if (isNullEmptyOrUndefined(string)) return string;
  return string.toLowerCase();
};

/**
 * Compares the two provided string values after converting them to lower case.
 *
 * @param {string} string1 The left hand string value used in the comparison.
 * @param {string} string2 The right hand string value used in the comparison.
 * @returns {boolean} True if both strings are equal once converted to lower case, otherwise false.
 */
export const areEqualLowerCaseStrings = (string1, string2) => {
  if (isNullEmptyOrUndefined(string1)) {
    throw new Error("string1 is Null, Empty or Undenied");
  }

  if (isNullEmptyOrUndefined(string2)) {
    throw new Error("string2 is Null, Empty or Undenied");
  }

  return string1.toLowerCase() === string2.toLowerCase();
};
