import React, {useContext} from "react";
import * as rowLabelColumnUtils from "./comparisonResultGridRowLableColumnUtils";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { columnConfiguration, renderMode } = props;
  const rows = rowLabelColumnUtils.getSortedDataRowsUsing(columnConfiguration, renderMode);
  const windowSize = useContext(WindowSizeContext);

  const isFirst = (row) => {
    return row.index ? row.index === 1 : false;
  };

  const isLast = (row) => {
    return row.index ? row.index === rows[rows.length - 1].index : false;
  };

  const calculateTopMargin = (renderMode, isLargeScreenView) => {
    return rowLabelColumnUtils.isVisible(renderMode) && isLargeScreenView ? "mt-24 " : "mt-0 ";
  };

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <div className={`lg:w-1/4 w-1/2 lg:block border-l border-t border-b border-gray-200 bg-gray-500 ${calculateTopMargin(renderMode, windowSize.isLargeScreenView)}`}>
      <div className="overflow-hidden border-gray-200">
        {rows.map((row) => {
          return (
            <p
              className={`${isFirst(row) ? `bg-yellow-500 h-32 text-gray-500` : `bg-gray-500 text-base`} bg-gray-500 ${
                isLast(row) ? `` : `-mt-px`
              } text-white h-12 px-2 flex text-center items-center justify-center`}
              key={uuidv4()}
            >
              {row.text}
            </p>
          );
        })}
      </div>
    </div>
  );
};
