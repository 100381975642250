import React, { useContext } from "react";
import useBlogData from "../../hooks/useBlogData";
import BlogIndexNewsLetterForm from "../BlogIndexNewsLetterForm";
import BlogIndexHSplitter from "../BlogIndexHSplitter";
import CoffeeGirl from "../../../svg/womandrinkingcoffee.svg";
import ComparisonResultPageBlogPostContainer from "../ComparisonResultPageBlogPostContainer";
import PropType from "prop-types";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { v4 as uuidv4 } from "uuid";
import AdDisclosure from "../AdDisclosure";

/**
 * Container component to render blog related content & the newsletter sign up form. Used in the right column of the
 * comparison results page.
 *
 * @param {string} blogContentCategory A string containing the logical blog category to show for the parent result page.
 */
const ComparisonResultPageBlogSideBar = (props) => {
  const blogData = useBlogData();
  const { blogPostData } = blogData;
  const { blogContentCategory } = props;
  const windowSize = useContext(WindowSizeContext);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <div className={`px-1 mx-auto bg-blue-100 ${windowSize.isLargeScreenView ? `mb-2 ` : ` `}`}>
      <CoffeeGirl className="w-10/12 mx-auto" />
      <BlogIndexHSplitter key={uuidv4()} />
      <ComparisonResultPageBlogPostContainer key={uuidv4()} blogPostData={blogPostData} blogContentCategory={blogContentCategory} />

      {windowSize.isLargeScreenView && <BlogIndexHSplitter key={uuidv4()} />}

      <div className={`${windowSize.isLargeScreenView ? `block ` : `hidden `} px-1 `}>
        <BlogIndexNewsLetterForm key={uuidv4()} />
        <AdDisclosure />
      </div>
    </div>
  );
};

ComparisonResultPageBlogSideBar.propTypes = {
  blogContentCategory: PropType.string.isRequired,
};

export default ComparisonResultPageBlogSideBar;
